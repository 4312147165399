import React from 'react'
import { Link } from 'gatsby'

import '../assets/scss/components/header.scss'
import { Expo, gsap } from 'gsap'

class Header extends React.Component {
  componentDidMount() {
    gsap.fromTo(
      '.bb-header-nav__link',
      {
        delay: 0.1,
        opacity: 0,
        y: -100,
        ease: 'expo.easeInOut',
      },
      {
        duration: 1,
        opacity: 1,
        y: 0,
        stagger: 0.1,
      }
    )
  }

  render() {
    return (
      <header className="bb-header">
        <div className="bb-header__body">
          <nav className="bb-header__nav">
            <Link to={'/'} className="bb-header-nav__link">
              Home
            </Link>
            <Link to={'/contact'} className="bb-header-nav__link">
              Contact
            </Link>
          </nav>
        </div>
        <div id="bb-progression-bar" className="bb-progression-bar" />
      </header>
    )
  }
}

export default Header
