import React from 'react'

import Header from '../components/Header'

import '../assets/scss/templates/layout.scss'

const Layout = (props) => (
  <section>
    <Header />
    <div className="bb-layout">{props.children}</div>
  </section>
)

export default Layout
