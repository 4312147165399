import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
// import * as THREE from 'three'
import { Expo, Linear, Power1, Power2, Power4 } from 'gsap'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import Layout from '../templates/layout'
import '../assets/scss/home.scss'

import ArrowRight from '../assets/csv/arrow-right'

class Home extends React.Component {
  constructor(props) {
    super(props)

    this.state = { x: 0, y: 0, leader: false, front: false, backend: false }
    // if (typeof window !== `undefined`) {
    //   this.controller = new ScrollMagic.Controller()
    // }
  }

  componentDidMount() {
    // build scenes

    // const width = this.mount.clientWidth
    // const height = this.mount.clientHeight
    // //ADD SCENE
    // this.scene = new THREE.Scene()
    // //ADD CAMERA
    // this.camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000)
    // this.camera.position.z = 4
    // //ADD RENDERER
    // this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true })
    // this.renderer.setClearColor('#ffffff')
    // this.renderer.setSize(width, height)
    // this.mount.appendChild(this.renderer.domElement)
    // //ADD CUBE
    // const geometry = new THREE.BoxGeometry(1, 1, 1)
    // const material = new THREE.MeshBasicMaterial({ color: '#433F81' })
    // this.cube = new THREE.Mesh(geometry, material)
    // this.scene.add(this.cube)
    // // this.start()

    this.scenes = []
    let scene

    // // THREE.js
    // scene = new ScrollMagic.Scene({
    //   triggerElement: '.bb-home-page__section-posts',
    //   duration: '100%',
    // })
    //   .on('progress', e => (this.camera.position.x = -e.progress))
    //   .addTo(this.controller)
    // this.scenes.push(scene)

    const height = gsap.getProperty('.bb-home-page__section-1', 'height')
    console.log(height)
    gsap.registerPlugin(ScrollTrigger)

    gsap
      .timeline()
      .fromTo(
        '.bb-home-page-section-1__img',
        {
          opacity: 0,
          x: '0px',
          'background-position': '60% 50%',
        },
        {
          duration: 2,
          opacity: 1,
          'background-position': '50% 50%',
          x: '20px',
          ease: 'expo.easeInOut',
        }
      )
      .fromTo(
        '.bb-home-page-section-1__img',
        {
          x: 0,
          'background-position': '50% 50%',
          opacity: 1,
        },
        {
          opacity: 0,
          x: '20px',
          'background-position': '40% 50%',
          scrollTrigger: {
            trigger: '.bb-home-page__section-1',
            start: `top-=60px top`,
            end: `+=30%`,
            toggleActions: 'play reverse play reverse',
            scrub: true,
            pin: true,
          },
        }
      )

    gsap.fromTo(
      '.bb-home-page-section-1__card',
      {
        delay: 0.1,
        opacity: 0,
        y: '-200px',
        ease: Expo.easeInOut,
      },
      {
        duration: 0.5,
        opacity: 1,
        y: 0,
      }
    )

    gsap.timeline().fromTo(
      '.bb-home-page-section-1__img',
      {},
      {
        scrollTrigger: {
          trigger: '.bb-home-page__section-leader',
          start: `top-=0px top`,
          end: `+=30%`,
          toggleActions: 'play reverse play reverse',
          scrub: true,
          pin: true,
        },
      }
    )

    gsap.timeline().fromTo(
      '.bb-home-page-section-1__img',
      {},
      {
        scrollTrigger: {
          trigger: '.bb-home-page__section-front',
          start: `top-=0px top`,
          end: `+=30%`,
          toggleActions: 'play reverse play reverse',
          scrub: true,
          pin: true,
        },
      }
    )

    // Part 2
    // tween = new Timeline()
    //   .staggerTo(
    //     '.bb-home-page-section-1__img-bg-2',
    //     250,
    //     {
    //       x: '-20px',
    //       opacity: 1,
    //       'background-position': '10% 50%',
    //       ease: Linear.easeNone,
    //     },
    //     150
    //   )
    //   .to('.bb-home-page-section-1__img-bg-2', 250, {})
    //   .staggerTo(
    //     '.bb-home-page-section-1__img-bg-2',
    //     360,
    //     {
    //       x: '20px',
    //       opacity: 0,
    //       'background-position': '90% 50%',
    //       ease: Linear.easeNone,
    //     },
    //     50
    //   )
    // scene = new ScrollMagic.Scene({
    //   triggerHook: 'onEnter',
    //   offset: '260px',
    //   duration: '1860px',
    // })
    //   .setTween(tween)
    //   .setPin('.bb-home-page-section-1__img-bg-step-2')
    //   .addTo(this.controller)
    // this.scenes.push(scene)
    //
    // tween = new Timeline().to(
    //   '.bb-home-page-section-1__card-2-left',
    //   1,
    //   { y: '+=-330px', ease: Power1.easeOut },
    //   '+=0.8'
    // )
    //
    // scene = new ScrollMagic.Scene({
    //   triggerHook: 'onEnter',
    //   offset: '260px',
    //   duration: '260px',
    // })
    //   .setTween(tween)
    //   .addTo(this.controller)
    // this.scenes.push(scene)
    //
    // scene = new ScrollMagic.Scene({
    //   triggerHook: 'onEnter',
    //   offset: '520px',
    //   duration: '1260px',
    // })
    //   .setPin('.bb-home-page-section-1__card-2-left')
    //   .addTo(this.controller)
    // this.scenes.push(scene)
    //
    // tween = new Timeline().to('.bb-home-page-section-1__card-2-left', 1, {
    //   y: '+=-830px',
    //   ease: Power1.easeIn,
    // })
    //
    // scene = new ScrollMagic.Scene({
    //   triggerHook: 'onEnter',
    //   offset: '780px',
    //   duration: '360px',
    // })
    //   .setTween(tween)
    //   .addTo(this.controller)
    // this.scenes.push(scene)
  }

  componentWillUnmount() {
    this.stop()
    // // this.mount.removeChild(this.renderer.domElement)
    // this.controller.destroy(true)
    // this.controller = null
    // this.scenes = this.scenes.map((s) => s == null)
  }

  start = () => {
    if (!this.frameId) {
      this.frameId = requestAnimationFrame(this.animate)
    }
  }
  stop = () => {
    cancelAnimationFrame(this.frameId)
  }
  animate = () => {
    this.cube.rotation.x += 0.01
    this.cube.rotation.y += 0.01

    this.renderScene()
    this.frameId = window.requestAnimationFrame(this.animate)
  }
  renderScene = () => {
    this.renderer.render(this.scene, this.camera)
  }

  _onMouseMove = (e) => {
    console.log(e)
    this.setState({ x: e.screenX, y: e.screenY })
  }

  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const siteDescription = get(
      this,
      'props.data.site.siteMetadata.description'
    )

    let classNameCard = ''
    this.state.leader
      ? (classNameCard =
          classNameCard + ' bb-home-page-section-1__card-hover-leader')
      : ''
    this.state.front
      ? (classNameCard =
          classNameCard + ' bb-home-page-section-1__card-hover-front')
      : ''
    this.state.backend
      ? (classNameCard =
          classNameCard + ' bb-home-page-section-1__card-hover-back')
      : ''

    return (
      <Layout location={this.props.location} onMouseMove={this._onMouseMove}>
        <Helmet
          id={'trigger'}
          htmlAttributes={{ lang: 'en' }}
          meta={[{ name: 'description', content: siteDescription }]}
          title={siteTitle}
        />
        <article className="bb-home-page">
          <div
            className="bb-home-page__background"
            ref={(mount) => {
              this.mount = mount
            }}
          />
          <section
            id="bb-home-page__section-1"
            className="bb-home-page__section-1"
          >
            <div className="bb-home-page-section-1__img" />
            <div className={`bb-home-page-section-1__card ${classNameCard}`}>
              <a
                onMouseEnter={() => this.setState({ leader: true })}
                onMouseLeave={() => this.setState({ leader: false })}
                className="bb-home-page-section-1-card__link bb-home-page-section-1-card__link-leader"
                href={'#leader'}
              >
                Leader
              </a>
              <a
                onMouseEnter={() => this.setState({ front: true })}
                onMouseLeave={() => this.setState({ front: false })}
                className="bb-home-page-section-1-card__link bb-home-page-section-1-card__link-front"
                href={'#front'}
              >
                Front
              </a>
              <a
                onMouseEnter={() => this.setState({ backend: true })}
                onMouseLeave={() => this.setState({ backend: false })}
                className="bb-home-page-section-1-card__link bb-home-page-section-1-card__link-back"
                href={'#back'}
              >
                Back
              </a>
              <h2 className="bb-home-page-section-1-card__name">
                Benjamin Delmas
              </h2>
              <ArrowRight className="bb-home-page-section-1-card__arrow" />
            </div>
          </section>
          <section id="leader" className="bb-home-page__section-leader">
            <div className="bb-home-page-section-leader__text">
              <h4>Team Leader</h4>
              <h4>asdasdads</h4>
              <p>"Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?"</p>
              <p>adasdasdasd</p>
              <h4>asdasdads</h4>
              <p>adasdasdasd</p>
            </div>
            <div className="bb-home-page-section-leader__icons"></div>
          </section>
          <section id="front" className="bb-home-page__section-front">
            <div className="bb-home-page-section-front__text">
              <h4>Frontend Developer</h4>
              <h4>asdasdads</h4>
              <p>"Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?"</p>
              <p>adasdasdasd</p>
              <h4>asdasdads</h4>
              <p>adasdasdasd</p>
            </div>
            <div className="bb-home-page-section-front__icons"></div>
          </section>
          <section id="back" className="bb-home-page__section-back">
            <div className="bb-home-page-section-back__text">
              <h4>Backend Engineer</h4>
              <h4>asdasdads</h4>
              <p>"Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?"</p>
              <p>adasdasdasd</p>
              <h4>asdasdads</h4>
              <p>adasdasdasd</p>
            </div>
            <div className="bb-home-page-section-back__icons"></div>
          </section>
        </article>
      </Layout>
    )
  }
}

export default Home

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
